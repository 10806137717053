<!--
 * @Description: 二次封装时间选择器
 * @Author: zhang zhen
 * @Date: 2023-02-06 15:52:35
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-04-12 23:50:31
 * @FilePath: /page-sass/src/components/easyComponents/JDate.vue
-->
<template>
  <a-date-picker
    dropdownClassName="j-date-picker"
    :disabled="disabled || readOnly"
    :placeholder="placeholder"
    @change="handleDateChange"
    :value="momVal"
    :showTime="showTime"
    :format="dateFormat"
    :disabledDate="removePast ? disabledDate : null"
  />
</template>
<script>
import moment from 'moment'
export default {
  name: 'PDate',
  props: {
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    value: {
      type: String,
      required: ''
    },
    passDate: {
      type: String,
      required: false
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD',
      required: false
    },
    //此属性可以被废弃了
    triggerChange: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showTime: {
      type: Boolean,
      required: false,
      default: false
    },
    getCalendarContainer: {
      type: Function,
      default: node => node.parentNode
    },
    removePast: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let dateStr = this.value
    return {
      decorator: '',
      momVal: !dateStr ? null : moment(dateStr, this.dateFormat)
    }
  },
  watch: {
    value(val) {
      if (!val) {
        this.momVal = null
      } else {
        this.momVal = moment(val, this.dateFormat)
      }
    }
  },
  methods: {
    moment,
    handleDateChange(mom, dateStr) {
      this.$emit('change', dateStr)
    },
    disabledDate(current) {
      // 获取当前日期
      const currentDate = this.passDate ? new Date(this.passDate) : new Date();
      // 设置当前日期为最大允许选择的日期
      currentDate.setHours(0, 0, 0, 0)

      // 将 current 转换为日期对象，设置时分秒为 0，以与 currentDate 进行比较
      const compareDate = new Date(current.format('YYYY-MM-DD'))
      compareDate.setHours(0, 0, 0, 0)

      // 如果 current 大于 currentDate，则禁用
      return compareDate < currentDate
    }
  },
  //2.2新建 在组件内定义 指定父组件调用时候的传值属性和事件类型 这个牛逼
  model: {
    prop: 'value',
    event: 'change'
  }
}
</script>
